<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ul class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        [perfectScrollbar]>
        <!--<li class="sidebar-user-panel">-->
          <!--&lt;!&ndash;<div class="user-panel">&ndash;&gt;-->
            <!--&lt;!&ndash;<div class=" image">&ndash;&gt;-->
              <!--&lt;!&ndash;<img src={{userImg}} class="img-circle user-img-circle" alt="User Image" />&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
          <!--&lt;!&ndash;</div>&ndash;&gt;-->
          <!--<div class="profile-usertitle">-->
            <!--<div class="sidebar-userpic-name">{{userFullName}}</div>-->
            <!--<div class="profile-usertitle-job ">-->
              <!--&lt;!&ndash; <span *ngIf="userType == 'HOI'" >-->
                <!--HOI-->
              <!--</span>-->
              <!--<span *ngIf="userType == 'Admin'; else elseBlock;" >-->
                <!--Admin-->
              <!--</span>-->
              <!--<ng-template #elseBlock>-->
                <!--{{userType}}-->
              <!--</ng-template>&ndash;&gt;-->
            <!--</div>-->
            <!--<div class="profile-usertitle-job ">{{userType}}</div>-->
          <!--</div>-->
        <!--</li>-->
        <!-- Top Most level menu -->
        <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
          *ngFor="let sidebarItem of sidebarItems" [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
          <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title}}</div>
          <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null"
            *ngIf="!sidebarItem.groupTitle &&  checkPermission(sidebarItem.path);" [ngClass]="[sidebarItem.class]"
            (click)="callLevel1Toggle($event, sidebarItem.moduleName)" class="menu-top">
            <!--<i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i>-->
            <img src="{{sidebarItem.imgSrc}}" />
            <span class="hide-menu">{{sidebarItem.title}}
            </span>
            <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
          </a>
          <a *ngIf="sidebarItem.path == './study-material/all-study-materials'">Amol Wavare</a>
          <!-- First level menu -->
          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
            <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''"
              *ngFor="let sidebarSubItem of sidebarItem.submenu"
              [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
              <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]"
                *ngIf="checkPermission(sidebarSubItem.path)">
                {{sidebarSubItem.title}}
              </a>
              <!-- Second level menu -->
              <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                  [ngClass]="level3Menu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                  [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                  <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                    (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                    [ngClass]="[sidebarSubsubItem.class]" *ngIf="checkPermission(sidebarSubsubItem.path)">
                    {{sidebarSubsubItem.title}}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
