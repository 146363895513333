import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  // {
  //   path: '/admin/dashboard/dashboard2',
  //   title: 'DASHBOARD',
  //   moduleName: ['dashboard'],
  //   iconType: 'material-icons-two-tone',
  //   imgSrc: 'assets/images/sidebar-logo/icons8-dashboard-layout-20.png',
  //   icon: 'home',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['All'],
  //   submenu: [],
  // }
];
