import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'role',
    loadChildren: () =>
      import('./role/role.module').then((m) => m.RoleModule),
  },
  {
    path: 'action',
    loadChildren: () =>
      import('./actions/action.module').then((m) => m.ActionModule),
  },
  {
    path: 'role-action',
    loadChildren: () =>
      import('./role-action/role-action.module').then((m) => m.RoleActionModule),
  },
  {
    path: 'user-role-action',
    loadChildren: () =>
      import('./user-role-action/user-role-action.module').then((m) => m.UserRoleActionModule),
  },
  {
    path: 'complaint-building',
    loadChildren: () =>
      import('./complaint-building/complaint-building.module').then((m) => m.ComplaintBuildingModule),
  },
  {
    path: 'complaint-office',
    loadChildren: () =>
      import('./complaint-office/complaint-office.module').then((m) => m.ComplaintOfficeModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class MasterRoutingModule {}
